import * as React from "react"
import "../styles/index.css"
import GoogleMapReact from 'google-map-react';
import MapMarker from '../images/Map-Marker-PNG-File.png'

const greatPlaceStyle = {
  position: 'absolute',
  transform: 'translate(-50%, -50%)'
}

const AnyReactComponent = ({ text }) => <div  style={greatPlaceStyle}>
<img src={MapMarker} alt="Logo" width={40} />
</div>;

const mapCoord = {
  lat: 49.163260449597324,
  lng: 16.593655786812395
}

const IndexPage = () => {
  return (
    <main>
      
      <div>

      <h1>
        AUTO HALSCH s.r.o.
        <div className="footer">
        <div><span>IČO: </span>17609887</div>
        <div><span>Registrace:</span>sp. zn. C 130707 vedená u Krajského soudu v Brně</div>
      </div>
      </h1>

      <div className="services">
        <div>Poskytujeme:</div>
        <ul>
          <li><h2>Pneuservis</h2></li>
          <li><h2>Údržba motorových vozidel a jejich příslušenství</h2></li>
        </ul>
      </div>

      <div className="container3">
        <div className="contact1">
          <div>Pavel Halsch</div>
          <div>JEDNATEL</div>
          <div><a href="tel:+420774977009">+420 774 977 009</a></div>
          <div><a href="mailto:kablero@centrum.cz">kablero@centrum.cz</a></div>
        </div>
        <div className="contact1">
          <div>Petr Koráb</div>
          <div>SERVISNÍ TECHNIK</div>
          <div><a href="tel:+420607831483">+420 607 831 483</a></div>
          <div></div>
        </div>
      </div>

      <div className="mapa">
        <div className="addressBox">
          <div>PROVOZOVNA:</div>
          <div>Bohunická 595/85</div>
          <div>Horní Heršpice</div>
          <div>619 00 Brno</div>
        </div>
        <div className="mapContainer">
        <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyB40GtTEat2faOVEd2Ll0ihI566X2hBKYA" }}
        defaultCenter={ {
          lat: mapCoord.lat, 
          lng: mapCoord.lng
        }}
        defaultZoom={16}
      >
          <AnyReactComponent
          lat={mapCoord.lat}
          lng={mapCoord.lng}
          text="My Marker"
        />

      </GoogleMapReact>
        </div>
      </div>
      <div className="footer">
        <div>AUTO HALSCH s.r.o.</div>
        <div><span>IČO: </span>17609887</div>
        <div><span>Registrace:</span>sp. zn. C 130707 vedená u Krajského soudu v Brně</div>
      </div>

      </div>
     
    </main>
  )
}

export default IndexPage

export const Head = () => <title>AUTO HALSCH s.r.o.</title>
